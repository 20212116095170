$(document).on('change', '#SearchInput',GetQueryUniversitiesEvents);
$(document).ready(function(){
	GetAllUniversities();
});
function GetAllUniversities(){
	$('#CollagesList').empty();
	var DataSend = {
		search:''
	};
    PostMethodFunctionWhitOutLoader('/API/Collage/GetQuery', DataSend, null, ShowAllUniversities, null);
}
function ShowAllUniversities(response){
	var AppendContent = '';
	$.each(response.collages, function(index, collage){
        AppendContent += '<li title="'+collage.name+'" class="CollagesListItem" onclick="location.href=\'Universidades/'+collage.id+'\'" style="background-image:url(images/collage/'+collage.id+'.'+collage.img+currentTimestap+');">';
        AppendContent += '</li>';
    });
	$('#CollagesList').append(AppendContent);
}
function GetQueryUniversitiesEvents(){
	$('#QueryList').empty();
	var DataSend = {
		search:$('#SearchInput').val()
	};
    PostMethodFunctionWhitOutLoader('/API/Collage/GetQuery', DataSend, null, ShowQueryUniversities, null);
}
function ShowQueryUniversities(response){
	if(response.collages.length>0){
		$('#QueryList').addClass('QueryListResults').removeClass('d-none').addClass('d-block');
	}else{
		$('#QueryList').removeClass('QueryListResults').removeClass('d-block').addClass('d-none');
	}
	var AppendContent = '';
	$.each(response.collages, function(index, collage){
        AppendContent += '<li title="'+collage.name+'" class="QueryListItem" onclick="location.href=\'Universidades/'+collage.id+'\'">';
			AppendContent += '<div class="QueryListItem_Image" style="background-image:url(images/collage/'+collage.id+'.'+collage.img+currentTimestap+');"></div>';
        	AppendContent += '<p class="QueryListItem_Name">'+collage.name+'</p>';
        AppendContent += '</div>';
    });
	$('#QueryList').append(AppendContent);
}